<template>
  <div class="container">
    <div v-if="cartList.length" class="goods-box">
      <van-cell title="商品列表" icon="shop-o">
        <div slot="default" class="status-tip" @click="onStatusClicked">{{ cartStatus }}</div>
      </van-cell>
      <van-checkbox-group ref="checkboxGroup" v-model="checkList" class="list">
        <van-checkbox
          v-for="(item,index) in cartList"
          :key="item.product_id + item.spec_id"
          class="list-item"
          checked-color="#00cca2"
          :name="item.product_id + '/' + item.spec_id"
          label-disabled
        >
          <van-card
            :title="item.product_name"
            :price="item.sale_price | formatPrice"
            :origin-price="item.market_price | formatPrice"
            :desc="item.specname"
            :thumb="item.catimg"
          >
            <div slot="num">
              <van-stepper v-model="item.count" :name="index" @change="onNumberChange" />
            </div>
          </van-card>
        </van-checkbox>
      </van-checkbox-group>
      <!-- 结算栏 -->

      <template v-if="cartStatus === '管理'">
        <van-submit-bar
          :price="totalPrice"
          button-text="提交订单"
          :disabled="!checkList.length"
          @submit="redirectOrderSubmit"
        >
          <div style="flex:1">
            <van-checkbox
              :checked-color="globalData.theme.color2"
              :value="checkAll"
              @click="toggleCheckAll"
            >全选</van-checkbox>
          </div>
        </van-submit-bar>
      </template>
      <template v-else>
        <van-submit-bar button-text="删除" :disabled="!checkList.length" @submit="cartDel">
          <div style="flex:1">
            <van-checkbox
              :checked-color="globalData.theme.color2"
              :value="checkAll"
              @click="toggleCheckAll"
            >全选</van-checkbox>
          </div>
        </van-submit-bar>
      </template>
    </div>
    <div v-else style="height: 100vh;background: #fff;">
      <div class="no-data">
        <img :src="noCart" alt>
        <div class="no-data-title">购物车快饿瘪了 T.T</div>
        <div class="no-data-txt">快给我挑点宝贝</div>
        <van-button class="no-data-btn" color="#00cca2" plain type="danger" @click="handleHomePage">去逛逛</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { Checkbox, CheckboxGroup, Card, Stepper, SubmitBar } from 'vant'
import noCart from '@/assets/no_cart.png'
import PageMixin from '@/mixins/page'

import storage from 'good-storage'

export default {
  name: 'Cart',
  components: {
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Card.name]: Card,
    [Stepper.name]: Stepper,
    [SubmitBar.name]: SubmitBar
  },
  filters: {
    formatPrice(val) {
      return parseFloat(val).toFixed(2)
    },
    formatPoint(val) {
      return parseFloat((val * 100).toFixed(2))
    },
    formatYuan(val) {
      return parseFloat((val / 100).toFixed(2))
    }
  },
  mixins: [PageMixin],
  data() {
    return {
      cartStatus: '管理', // 管理,完成
      checkList: [],
      cartList: [],
      noCart

    }
  },
  computed: {
    checkAll() {
      return this.cartList.length === this.checkList.length
    },
    submitBarText() {
      const count = this.checkList.length
      return '结算' + (count ? `(${count})` : '')
    },
    totalPrice() {
      const price = this.cartList.reduce((total, item) => total + (this.checkList.indexOf(`${item.product_id}/${item.spec_id}`) !== -1 ? item.sale_price * item.count : 0), 0)
      return parseFloat((price * 100).toFixed(2))
    }
  },
  created() {
    this.queryCart()
  },
  methods: {
    queryCart() {
      this.$api.cart_get().then(res => {
        this.cartList = res.data.cart_datas
        // this.checkList = res.data.cart_datas.map(item => `${item.product_id}/${item.spec_id}`)
        if (this.cartList.length) {
          this.$nextTick(() => {
            this.$refs.checkboxGroup.toggleAll(true)
          })
        }
      })
    },
    handleHomePage() {
      this.$router.replace({ path: '/mall' })
    },
    onStatusClicked() {
      this.cartStatus === '管理' ? this.cartStatus = '完成' : this.cartStatus = '管理'
    },
    toggleCheckAll() {
      if (this.checkList.length >= 0 && this.checkList.length < this.cartList.length) {
        this.$refs.checkboxGroup.toggleAll(true)
      } else {
        this.$refs.checkboxGroup.toggleAll(false)
      }
    },
    onSubmit() {
      // this.cartStatus === '管理' ? this.cartDel() : this.redirectOrder()
    },
    onNumberChange(val, { name: index }) {
      // console.log('onNumberChange', val, index)
      const count = val
      const spec_id = this.cartList[index].spec_id
      const product_id = this.cartList[index].product_id
      this.cartReduce(product_id, spec_id, count)
    },
    cartReduce(product_id, spec_id, count) {
      this.$toast.loading({
        mask: true,
        message: '修改中...',
        duration: 0
      })
      this.$api
        .cart_reduce({ product_id, spec_id, count })
        .then(res => {
          this.$toast.success('修改数量成功')
          this.queryCart()
          // this.queryCartCount()
          // this.skuShow = false
          // if (this.skuActionType === 'buy') {
          //   this.redirectOrderSubmit()
          // }
        })
        .catch(err => {
          this.$toast.fail('修改数量失败')
          console.error(err)
        })
    },
    cartDel() {
      this.$dialog.confirm({
        message: `确定要删除这${this.checkList.length}个商品吗?`
      })
        .then(() => {
          const product = this.checkList.map(item => ({ product_id: item.split('/')[0], spec_id: item.split('/')[1] }))
          this.$api.cart_clear({ product: product }).then(res => {
            this.checkList.forEach((item, index) => {
              this.cartList.splice(index, 1)
            })
          })
        })
        .catch(err => {
          console.error(err)
        })
    },
    redirectOrderSubmit() {
      // const cartInfo = this.cartList.filter(item => this.checkList.indexOf(`${item.product_id}/${item.spec_id}`) > -1)
      // storage.session.set('cartInfo', cartInfo)

      storage.session.remove('choseCoupon')
      storage.session.remove('choseAddress')
      this.$router.push({ path: '/order-submit' })
    }
  }
}
</script>
<style lang="less" scoped>
.status-tip {
  position: relative;
  overflow: hidden;
  color: #00cca2;
  text-align: right;
  vertical-align: middle;
}
.list {
  padding: 10px 0;
  background-color: #fff;
  &-item {
    position: relative;
    .van-card {
      background-color: #fff;
    }
    /deep/ .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }
    /deep/ .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
      font-size: 16px;
    }
    .van-card__price {
      color: #00cca2;
    }
    
    .van-card__bottom {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }
}
.delete-bar {
  .van-submit-bar__bar {
    margin-left: 10px;
    justify-content: space-between;
    align-items: center;
  }
  /deep/.van-checkbox__icon--checked .van-icon {
    background-color: #00cca2;
    border-color: #00cca2;
  }
}
.submit-bar {
  bottom: 50px;
  .van-checkbox {
    margin-left: 10px;
  }
  /deep/.van-checkbox__icon--checked .van-icon {
    background-color: #00cca2;
    border-color: #00cca2;
  }
}
.no-data {
  padding-top: 150px;
  text-align: center;
  > img {
    display: inline-block;
    width: 290px;
    margin-bottom: 20px;
  }
  &-title {
    font-size: 16px;
    color: #666;
    font-weight: 400;
    margin-bottom: 10px;
  }
  &-txt {
    font-size: 14px;
    color: #999;
    margin-bottom: 10px;
  }
  &-btn {
    padding: 0 20px;
    height: 34px;
    line-height: 32px;
  }
}
.goods-box{
  .van-submit-bar__price{
    color: #00cca2;
  }
}
</style>

